/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./locations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/card/card.component.ngfactory";
import * as i3 from "../../components/card/card.component";
import * as i4 from "../../components/action-button/action-button.component.ngfactory";
import * as i5 from "../../components/action-button/action-button.component";
import * as i6 from "../../components/list/list.component.ngfactory";
import * as i7 from "../../components/list/list.component";
import * as i8 from "../../components/pagination/pagination.component.ngfactory";
import * as i9 from "../../components/pagination/pagination.component";
import * as i10 from "../../services/list/list.service";
import * as i11 from "../../services/api/api.service";
import * as i12 from "ngx-bootstrap/modal";
import * as i13 from "./locations.component";
import * as i14 from "ngx-toastr";
var styles_LocationsComponent = [i0.styles];
var RenderType_LocationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationsComponent, data: {} });
export { RenderType_LocationsComponent as RenderType_LocationsComponent };
function View_LocationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [["width", "25%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Id"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [["width", "20%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Type"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["width", "30%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [["width", "25%"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ParentId"]))], null, null); }
function View_LocationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.item.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.item.type; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.item.name; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.item.parentId; _ck(_v, 7, 0, currVal_3); }); }
export function View_LocationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "app-card", [["title", "Locations"]], null, null, null, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "ul", [["class", "actions-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-action-button", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.openImportFromCsv() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ActionButtonComponent_0, i4.RenderType_ActionButtonComponent)), i1.ɵdid(4, 114688, null, 0, i5.ActionButtonComponent, [], null, { actionEvent: "actionEvent" }), (_l()(), i1.ɵted(-1, 0, ["Import from CSV"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-action-button", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.exportCSV() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ActionButtonComponent_0, i4.RenderType_ActionButtonComponent)), i1.ɵdid(7, 114688, null, 0, i5.ActionButtonComponent, [], null, { actionEvent: "actionEvent" }), (_l()(), i1.ɵted(-1, 0, ["Export CSV"])), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "app-list", [], null, null, null, i6.View_ListComponent_0, i6.RenderType_ListComponent)), i1.ɵdid(10, 114688, null, 0, i7.ListComponent, [], { loading: [0, "loading"], items: [1, "items"], emptyMessage: [2, "emptyMessage"], headerTemplate: [3, "headerTemplate"], bodyTemplate: [4, "bodyTemplate"] }, null), (_l()(), i1.ɵand(0, [["header", 2]], 0, 0, null, View_LocationsComponent_1)), (_l()(), i1.ɵand(0, [["body", 2]], 0, 0, null, View_LocationsComponent_2)), (_l()(), i1.ɵeld(13, 0, null, 0, 1, "app-pagination", [], null, [[null, "pageChangedEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChangedEvent" === en)) {
        var pd_0 = (_co.pageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_PaginationComponent_0, i8.RenderType_PaginationComponent)), i1.ɵdid(14, 114688, null, 0, i9.PaginationComponent, [], { totalItems: [0, "totalItems"] }, { pageChangedEvent: "pageChangedEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Locations"; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 7, 0); var currVal_1 = _co.list.loading; var currVal_2 = _co.list.items; var currVal_3 = _co.emptyMessage; var currVal_4 = i1.ɵnov(_v, 11); var currVal_5 = i1.ɵnov(_v, 12); _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.list.totalItems; _ck(_v, 14, 0, currVal_6); }, null); }
export function View_LocationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-locations", [], null, null, null, View_LocationsComponent_0, RenderType_LocationsComponent)), i1.ɵprd(512, null, i10.ListService, i10.ListService, [i11.ApiService, i12.BsModalService]), i1.ɵdid(2, 114688, null, 0, i13.LocationsComponent, [i10.ListService, i14.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LocationsComponentNgFactory = i1.ɵccf("app-locations", i13.LocationsComponent, View_LocationsComponent_Host_0, {}, {}, []);
export { LocationsComponentNgFactory as LocationsComponentNgFactory };
