import { constants } from "../constants";
import * as i0 from "@angular/core";
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.prototype.isAuthenticated = function () {
        if (this.getToken()) {
            return true;
        }
        return false;
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem(constants.TOKEN);
    };
    AuthService.prototype.setToken = function (token) {
        localStorage.setItem(constants.TOKEN, token);
    };
    AuthService.prototype.logOut = function () {
        localStorage.clear();
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
