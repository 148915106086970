<div class="list">
  <table class="table">
    <thead>
      <tr>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        <th *ngIf="anyActions()"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngIf="loading"
        [@enterAnimation]
        class="--loading"
      ></tr>
      <tr *ngIf="items && items.length === 0">
        <td
          class="--empty-message"
          colSpan="7"
        >
          {{emptyMessage}}
        </td>
      </tr>
      <tr
        *ngFor="let item of items; let campaign of campaigns; let i = index;"
        [class.--is-peer]="i%2"
      >
        <ng-container *ngTemplateOutlet="bodyTemplate;context:{item: item}"></ng-container>
        <td
          class="actions"
          *ngIf="anyActions()"
        >
          <button
            class="actions--edit btn border btn-sm"
            (click)="openEdit(item)"
            *ngIf="actions.edit"
          >
            <fa-icon [icon]="editIcon"></fa-icon>
          </button>
          <button
            (click)="openRemove(item)"
            class="actions--remove btn btn-sm btn-danger"
            *ngIf="actions.remove"
          >
            <fa-icon [icon]="removeIcon"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
