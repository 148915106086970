import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { entityPlural, entitySingular } from "../constants";
import { IAccount, ICampaign, IListResponse, ILocation } from "./api.interface";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public apiUrl = `${environment.API}/api/v1`;
  constructor(private httpClient: HttpClient) {}

  public getPaginated<T>(entity: entityPlural, page: string, limit: string) {
    return this.httpClient.get<IListResponse<T>>(`${this.apiUrl}/${entity}`, { params: { page, limit } });
  }

  public delete<T>(entity: entitySingular, id: string) {
    return this.httpClient.delete(`${this.apiUrl}/${entity}/${id}`);
  }

  public getCampaigns(page: string, limit: string) {
    return this.httpClient.get<IListResponse<ICampaign>>(`${this.apiUrl}/campaign`, { params: { page, limit } });
  }

  public getOneCampaign(id: string) {
    return this.httpClient.get<ICampaign>(`${this.apiUrl}/campaign/${id}`);
  }

  public createCampaign(campaign: ICampaign) {
    return this.httpClient.post<ICampaign>(`${this.apiUrl}/campaign`, campaign);
  }

  public updateCampaign(campaign: ICampaign) {
    return this.httpClient.put<ICampaign>(`${this.apiUrl}/campaign/${campaign.id}`, campaign);
  }

  public getAllLocations() {
    return this.httpClient.get<IListResponse<ILocation>>(`${this.apiUrl}/locations`);
  }

  public importCSV(entity: entitySingular, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.httpClient.post<{ response: string }>(`${this.apiUrl}/${entity}/file/import`, formData);
  }

  public exportCSV(entity: entitySingular) {
    const headers = new HttpHeaders().set("Content-Type", "text/csv");
    return this.httpClient.get<{ response: string }>(`${this.apiUrl}/${entity}/file/export`, {
      responseType: "arraybuffer" as "json",
      headers: headers,
    });
  }

  public getOfflineAccounts() {
    return this.httpClient.get<IListResponse<IAccount>>(`${this.apiUrl}/accounts/offline`);
  }

  public getOnlineAccounts(page: string, limit: string) {
    return this.httpClient.get<IListResponse<IAccount>>(`${this.apiUrl}/accounts/online`, { params: { page, limit } });
  }

  public deleteAccount(id: string) {
    return this.httpClient.delete(`${this.apiUrl}/account/${id}`);
  }
}
