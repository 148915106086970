<app-card title="Offline campaings">
  <ul class="actions-group">
    <app-action-button
      [icon]="addIcon"
      (actionEvent)="openEdit()"
    >
      Add new
    </app-action-button>
    <app-action-button (actionEvent)="openImportFromCsv()">Import from CSV</app-action-button>
    <app-action-button (actionEvent)="exportCSV()">Export CSV</app-action-button>
  </ul>
  <app-list
    [items]="list.items"
    [headerTemplate]="header"
    [bodyTemplate]="body"
    [emptyMessage]="emptyMessage"
    [loading]="list.loading"
    (editEvent)="openEdit($event)"
    (removeEvent)="openRemove($event)"
    [actions]="{remove: true, edit: true}"
  ></app-list>
  <ng-template #header>
    <th>Media</th>
    <th>Name</th>
    <th>Origin</th>
    <th>Destination</th>
    <th>Budget</th>
    <th>P. Reach</th>
    <th>Dates</th>
  </ng-template>
  <ng-template
    #body
    let-campaign="item"
  >
    <td>{{ campaign.accountId }}</td>
    <td>{{ campaign.name }}</td>
    <td>{{ campaign.originId }}</td>
    <td>{{ campaign.destinationId }}</td>
    <td>{{ campaign.budget | currency:'EUR' }}</td>
    <td>{{ campaign.potentialReach }}</td>
    <td>
      {{ campaign.startDate | date: "dd/MM/yyyy" }} - {{ campaign.endDate | date: "dd/MM/yyyy" }}
    </td>
  </ng-template>
  <app-pagination
    [totalItems]="list.totalItems"
    (pageChangedEvent)="pageChanged($event)"
  ></app-pagination>
</app-card>
