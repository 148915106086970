<div class="modal-header">
  <h4 class="modal-title pull-left">Upload file</h4>
</div>
<div class="modal-body">
  <div class="center">
    <file-drop
      [headertext]="file ? file.name: 'Drop files here'"
      (onFileDrop)="dropped($event)"
      [showBrowseBtn]="true"
      accept="png"
    >
    </file-drop>
  </div>
  <div class="row mt-4 center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-block btn-default border"
        name="ok"
        [disabled]="!file"
        (click)="confirm(true)"
      >
        ok
      </button>
    </div>
    <div class="col-3">
      <button
        type="button"
        class="btn btn-block btn-primary"
        (click)="confirm(false)"
        name="cancel"
      >
        Cancel
      </button>
    </div>
  </div>

</div>
