<div class="pagination">
  <select
    [(ngModel)]="itemsPerPage"
    (ngModelChange)="pageOrItemsPerPageChanged()"
    class="pagination--select form-control"
  >
    <option
      *ngFor="let numPages of [10, 20, 30]"
      [value]="numPages"
    >
      {{ numPages }} per page
    </option>
  </select>
  <pagination
    class="pagination--pagination"
    [maxSize]="5"
    [totalItems]="totalItems"
    [itemsPerPage]="itemsPerPage"
    (pageChanged)="pageOrItemsPerPageChanged($event)"
  ></pagination>
</div>
