import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ImportFromCsvComponent } from "src/app/components/import-from-csv/import-from-csv.component";
import { IPageChangedEvent } from "src/app/components/pagination/pagination.interface";
import { RemoveComponent } from "src/app/components/remove/remove.component";
import { IRemoveInputs } from "src/app/components/remove/remove.interface";
import { ApiService } from "../api/api.service";
import { entityPlural, entitySingular } from "../constants";
import { Status } from "./list.interface";

@Injectable({
  providedIn: "root",
})
export class ListService<T> {
  public page: string;
  public itemsPerPage: string;
  public totalItems: number;
  public loading: boolean;

  public items: T[];
  public bsModalRef: BsModalRef;

  constructor(private apiService: ApiService, private modalService: BsModalService) {
    this.page = "1";
    this.itemsPerPage = "10";
  }

  public async getItems(entity: entityPlural, event?: IPageChangedEvent): Promise<boolean> {
    this.loading = true;
    if (event) {
      this.itemsPerPage = event.itemsPerPage;
      this.page = event.page;
    }
    try {
      const { list, totalItems } = await this.apiService.getPaginated<T>(entity, this.page, this.itemsPerPage).toPromise();
      this.items = list;
      this.totalItems = totalItems;
      this.loading = false;
      return true;
    } catch (error) {
      this.loading = false;
      return false;
    }
  }

  public async openRemove(entity: entitySingular, id: string, modal?: IRemoveInputs): Promise<Status> {
    this.bsModalRef = this.modalService.show(RemoveComponent, {
      initialState: {
        title: (modal && modal.title) || `Remove ${entity}`,
        text: (modal && modal.text) || "Are you sure?",
      },
    });
    const confirm = await new Promise<boolean>((resolve, reject) => {
      this.bsModalRef.content.confirmEvent.subscribe((res: boolean) => {
        resolve(res);
      });
    });
    if (confirm) {
      try {
        await this.apiService.delete(entity, id).toPromise();
        this.bsModalRef.hide();
        return Status.SUCCESS;
      } catch (error) {
        this.bsModalRef.hide();
        return Status.ERROR;
      }
    }
    this.bsModalRef.hide();
    return Status.NOTHING;
  }

  public async openImportFromCsv(entity: entitySingular): Promise<Status> {
    this.bsModalRef = this.modalService.show(ImportFromCsvComponent);
    const file = await new Promise<File>((resolve, reject) => {
      this.bsModalRef.content.confirmEvent.subscribe((res: File) => {
        resolve(res);
      });
    });
    if (file) {
      try {
        await this.apiService.importCSV(entity, file).toPromise();
        this.bsModalRef.hide();
        return Status.SUCCESS;
      } catch (error) {
        this.bsModalRef.hide();
        return Status.ERROR;
      }
    }
    this.bsModalRef.hide();
    return Status.NOTHING;
  }

  public async exportCSV(entity: entitySingular, fileName: string): Promise<boolean> {
    try {
      const response = await this.apiService.exportCSV(entity).toPromise();
      const file = new Blob([response as any], { type: "text/csv" });
      const exportUrl = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.setAttribute("download", fileName);
      link.target = "_self";
      link.href = exportUrl;
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    } catch (error) {
      return false;
    }
  }
}
