import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { IListActions } from "./list.interface";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("500ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("500ms", style({ opacity: 0 }))]),
    ]),
  ],
})
export class ListComponent implements OnInit {
  public editIcon = faPencilAlt;
  public removeIcon = faTrashAlt;
  @Input() public loading: boolean;
  @Input() public items: any[];
  @Input() public emptyMessage: string;
  @Input() public headerTemplate: TemplateRef<any>;
  @Input() public bodyTemplate: TemplateRef<any>;
  @Input() public actions: IListActions = { remove: false, edit: false };

  @Output() public editEvent: EventEmitter<any> = new EventEmitter();
  @Output() public removeEvent: EventEmitter<any> = new EventEmitter();
  constructor() {}

  public ngOnInit() {}

  public openEdit(item: any) {
    this.editEvent.emit(item);
  }

  public openRemove(item: any) {
    this.removeEvent.emit(item);
  }

  public anyActions() {
    return Object.keys(this.actions).some(actionName => this.actions[actionName]);
  }
}
