import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {}

  public ngOnInit() {
    const token = this.activatedRoute.snapshot.queryParamMap.get("token");
    this.authService.setToken(token);
    this.router.navigate(["/"]);
  }
}
