/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./app.component";
import * as i8 from "./services/auth/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "content-app__menu__links--link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "link"], ["routerLinkActive", "--is-active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "fa-icon", [["class", "link__icon ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(7, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "link__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.path; _ck(_v, 2, 0, currVal_2); var currVal_3 = "--is-active"; _ck(_v, 3, 0, currVal_3); var currVal_5 = _v.context.$implicit.icon; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 7).renderedIconHTML; _ck(_v, 6, 0, currVal_4); var currVal_6 = _v.context.$implicit.name; _ck(_v, 9, 0, currVal_6); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "header__logo--image"], ["src", "assets/icon/small-logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "header__buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "header__buttons--button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Logout "])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "content-app"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "content-app__menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "ul", [["class", "content-app__menu__links"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(10, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "content-app__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.routes; _ck(_v, 10, 0, currVal_0); _ck(_v, 14, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i7.AppComponent, [i8.AuthService, "Window"], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
