import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { IPageChangedEvent } from "src/app/components/pagination/pagination.interface";
import { Errors, pathRoutes, Success } from "src/app/constants";
import { ICampaign } from "src/app/services/api/api.interface";
import { entityPlural, entitySingular } from "src/app/services/constants";
import { ListService } from "src/app/services/list/list.service";

@Component({
  selector: "app-offline-campaigns",
  templateUrl: "./offline-campaigns.component.html",
  styleUrls: ["./offline-campaigns.component.scss"],
  providers: [ListService],
})
export class OfflineCampaignsComponent implements OnInit {
  public addIcon = faPlus;

  public campaigns: ICampaign[];
  public readonly emptyMessage = Success.CAMPAIGNS_EMPTY;

  constructor(public list: ListService<ICampaign>, private router: Router, private toastr: ToastrService) {}

  public ngOnInit() {
    this.pageChanged();
  }

  public openEdit(campaign?: ICampaign) {
    this.router.navigate([pathRoutes.CAMPAING, campaign ? campaign.id : "new"]);
  }

  public async openRemove(campaign: ICampaign): Promise<void> {
    const res = await this.list.openRemove(entitySingular.CAMPAIGN, campaign.id);
    if (res === "success") {
      this.toastr.success(Success.CAMPAIGN_DELETE);
      this.pageChanged();
    } else if (res === "error") {
      this.toastr.error(Errors.CAMPAIGN_DELETE);
    }
  }

  public async pageChanged(event?: IPageChangedEvent): Promise<void> {
    const response = await this.list.getItems(entityPlural.CAMPAIGNS, event);
    if (!response) {
      this.toastr.error(Errors.CAMPAIGN_READ);
    }
  }

  public async openImportFromCsv(): Promise<void> {
    const res = await this.list.openImportFromCsv(entitySingular.CAMPAIGN);
    if (res === "success") {
      this.toastr.success(Success.UPLOAD_CSV);
      this.pageChanged();
    } else if (res === "error") {
      this.toastr.error(Errors.UPLOAD_CSV);
    }
  }

  public exportCSV(): void {
    this.list.exportCSV(entitySingular.CAMPAIGN, "offline-campaigns.csv");
  }
}
