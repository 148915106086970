import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { UploadEvent, FileSystemFileEntry, UploadFile, FileSystemDirectoryEntry } from "ngx-file-drop";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: "app-import-from-csv",
  templateUrl: "./import-from-csv.component.html",
  styleUrls: ["./import-from-csv.component.scss"],
})
export class ImportFromCsvComponent implements OnInit {
  @Output() public confirmEvent = new EventEmitter<File | null>();

  public files: UploadFile[] = [];

  public file: File;

  constructor() {}

  public ngOnInit() {}

  public dropped(event: UploadEvent): void {
    this.files = event.files;
    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;
        });
      }
    }
  }

  public confirm(confirm: boolean): void {
    this.confirmEvent.emit(confirm ? this.file : null);
  }
}
