import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}
  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.auth.getToken();
    const authReq = req.clone({
      headers: authToken ? req.headers.set("Authorization", `Bearer ${authToken}`) : req.headers,
    });

    return next.handle(authReq).pipe(
      tap(
        event => true,
        (errorRes: HttpErrorResponse) => {
          console.error(errorRes);
          if (errorRes.status === 401) {
            localStorage.clear();
            location.reload();
          }
        },
      ),
    );
  }
}
