<section class="edit">
  <div class="edit__big-card">
    <div class="edit__big-card__header">
      <h2 class="edit__big-card__header--title">
        {{!isEdit ? 'Create campaign' : 'Edit campaign'}}
      </h2>
    </div>
    <div class="edit__big-card__content">
      <ng-template #loading>
        <span class="loading"></span>
      </ng-template>
      <form
        class="edit__big-card__content__form"
        *ngIf="(!isEdit || (campaign | async)) && (locations$ | async) && (offlineAccounts$ | async); else loading"
        [formGroup]="form"
      >
        <div class="row">
          <div class="form-group col-md-4">
            <label>Campaign Name*</label>
            <input
              class="form-control"
              formControlName="name"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Channel*</label>
            <input
              class="form-control"
              formControlName="channelType"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Media*</label>
            <select
              formControlName="accountId"
              class="form-control"
            >
              <option
                *ngFor="let offlineAccount of offlineAccounts"
                [value]="offlineAccount.id"
              >
                {{offlineAccount.id}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label>SH/MH/LH</label>
            <select
              formControlName="haul"
              class="form-control"
            >
              <option
                *ngFor="let option of haul"
                [value]="option"
              >
                {{option}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label>Status*</label>
            <select
              formControlName="status"
              class="form-control"
            >
              <option
                *ngFor="let option of status"
                [value]="option"
              >
                {{option}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label>Out/In</label>
            <select
              formControlName="outIn"
              class="form-control"
            >
              <option
                *ngFor="let option of outIn"
                [value]="option"
              >
                {{option}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label>Owner</label>
            <select
              formControlName="owner"
              class="form-control"
            >
              <option
                *ngFor="let option of owner"
                [value]="option"
              >
                {{option}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label>Campaign Type</label>
            <select
              formControlName="type"
              class="form-control"
            >
              <option
                *ngFor="let option of campaignType"
                [value]="option"
              >
                {{option}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Targeting</label>
            <input
              class="form-control"
              formControlName="channelTargeting"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Landing Page</label>
            <input
              class="form-control"
              formControlName="landingPage"
            >
          </div>
          <div class="form-group col-md-4">
            <label>POS</label>
            <input
              class="form-control"
              formControlName="pos"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Target Location</label>
            <input
              class="form-control"
              formControlName="targetLocation"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Activity Type</label>
            <input
              class="form-control"
              formControlName="activityType"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Creative</label>
            <input
              class="form-control"
              formControlName="creative"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Format</label>
            <input
              class="form-control"
              formControlName="format"
            >
          </div>
          <ng-template
            #customItemTemplate
            let-location="item"
          >
            {{location.name}} ({{location.type}})
          </ng-template>
          <div class="form-group has-danger col-md-4">
            <label>Origin</label>

            <input
              formControlName="originId"
              [typeahead]="locations"
              [ngClass]="{'form-control-danger': form.get('originId').invalid}"
              typeaheadOptionField="id"
              [typeaheadItemTemplate]="customItemTemplate"
              class="form-control form-control-danger"
            >
          </div>
          <div class="form-group col-md-4">
            <label>Destination</label>

            <input
              formControlName="destinationId"
              [typeahead]="locations"
              typeaheadOptionField="id"
              [typeaheadItemTemplate]="customItemTemplate"
              class="form-control"
            >
          </div>

          <div class="form-group col-md-4">
            <label>Dates*</label>
            <input
              type="text"
              class="form-control"
              bsDaterangepicker
              [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default' }"
              formControlName="dates"
              placement="top"
            >
          </div>
          <div class="form-group col-md-2">
            <label>Budget (€)*</label>
            <input
              type="number"
              class="form-control"
              formControlName="budget"
            >
          </div>
          <div class="form-group col-md-2">
            <label>Cost (€)*</label>
            <input
              type="number"
              class="form-control"
              formControlName="cost"
            >
          </div>
          <div class="form-group col-md-2">
            <label>Potencial Reach*</label>
            <input
              type="number"
              class="form-control"
              formControlName="potentialReach"
            >
          </div>
          <div class="form-group col-md-8">
            <label>Tags</label>
            <tag-input
              class="tags-input"
              theme="bootstrap3-info"
              formControlName="tags"
              name="items"
              [theme]="'foundation-theme'"
            >
            </tag-input>
          </div>
        </div>

        <div class="edit__big-card__content__form__buttons">
          <button
            type="button"
            class="edit__big-card__content__form__buttons--button-cancel"
            (click)="gotToCampaigns()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="edit__big-card__content__form__buttons--button-save"
            [disabled]="!form.dirty || form.invalid"
            (click)="save()"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
