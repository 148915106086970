import { Component, OnInit } from "@angular/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { IPageChangedEvent } from "src/app/components/pagination/pagination.interface";
import { Errors, Success } from "src/app/constants";
import { ILocation } from "src/app/services/api/api.interface";
import { entityPlural, entitySingular } from "src/app/services/constants";
import { Status } from "src/app/services/list/list.interface";
import { ListService } from "src/app/services/list/list.service";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
  providers: [ListService],
})
export class LocationsComponent implements OnInit {
  public addIcon = faPlus;

  public readonly emptyMessage = Success.CAMPAIGNS_EMPTY;

  constructor(public list: ListService<ILocation>, private toastr: ToastrService) {}

  public ngOnInit() {
    this.pageChanged();
  }

  public async pageChanged(event?: IPageChangedEvent): Promise<void> {
    const response = await this.list.getItems(entityPlural.LOCATIONS, event);
    if (!response) {
      this.toastr.error(Errors.LOCATION_READ);
    }
  }

  public async openImportFromCsv(): Promise<void> {
    const res = await this.list.openImportFromCsv(entitySingular.LOCATION);
    if (res === Status.SUCCESS) {
      this.toastr.success(Success.UPLOAD_CSV);
      this.pageChanged();
    } else if (res === "error") {
      this.toastr.error(Errors.UPLOAD_CSV);
    }
  }

  public exportCSV(): void {
    this.list.exportCSV(entitySingular.LOCATION, "locations.csv");
  }
}
