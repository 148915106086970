var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ECampaignOwner, ECampaignType, ECampaignStatus, ECampaignOutIn, ECampaignHaul, } from "src/app/services/api/api.interface";
import { tap } from "rxjs/operators";
import { pathRoutes, Success, Errors } from "src/app/constants";
var EditComponent = /** @class */ (function () {
    function EditComponent(activatedRoute, router, apiService, fb, toastr) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.apiService = apiService;
        this.fb = fb;
        this.toastr = toastr;
        this.haul = [ECampaignHaul.SH, ECampaignHaul.MH, ECampaignHaul.LH];
        this.outIn = [ECampaignOutIn.OUT, ECampaignOutIn.IN];
        this.status = [ECampaignStatus.ACTIVE, ECampaignStatus.INACTIVE];
        this.campaignType = [ECampaignType.B, ECampaignType.G];
        this.owner = [ECampaignOwner.B2B, ECampaignOwner.B2C];
    }
    EditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var id = this.activatedRoute.snapshot.paramMap.get("id");
        this.isEdit = id !== "new";
        this.locations = [];
        this.form = this.fb.group({
            id: [null],
            name: ["", Validators.required],
            accountId: ["", Validators.required],
            channelType: ["", Validators.required],
            channelTargeting: [""],
            haul: [""],
            outIn: [""],
            status: ["", Validators.required],
            type: [""],
            owner: [""],
            originId: [""],
            originType: [""],
            destinationId: [""],
            destinationType: [""],
            dates: ["", Validators.required],
            budget: ["", Validators.required],
            cost: ["", Validators.required],
            landingPage: [""],
            pos: [""],
            targetLocation: [""],
            creative: [""],
            format: [""],
            activityType: [""],
            potentialReach: ["", Validators.required],
            tags: [[]],
        });
        this.initForm();
        if (this.isEdit) {
            this.campaign = this.apiService.getOneCampaign(id).pipe(tap(function (campaign) {
                _this.form.patchValue(__assign({}, campaign, { tags: campaign.tags ? campaign.tags.map(function (t) { return t.id; }) : [], dates: [new Date(campaign.startDate), new Date(campaign.endDate)] }));
            }));
        }
        this.locations$ = this.apiService.getAllLocations().pipe(tap(function (_a) {
            var list = _a.list;
            _this.locations = list;
        }));
        this.offlineAccounts$ = this.apiService.getOfflineAccounts().pipe(tap(function (_a) {
            var list = _a.list;
            _this.offlineAccounts = list;
        }));
        this.checkLocations("destination");
        this.checkLocations("origin");
    };
    EditComponent.prototype.gotToCampaigns = function () {
        this.router.navigate([pathRoutes.OFFLINE_CAMPAINGS]);
    };
    EditComponent.prototype.save = function () {
        var _this = this;
        var campaign = this.form.value;
        campaign = __assign({}, campaign, { startDate: campaign.dates[0], endDate: campaign.dates[1], budget: campaign.budget.toString(), cost: campaign.cost.toString(), potentialReach: campaign.potentialReach.toString(), tags: campaign.tags ? campaign.tags.map(function (c) { return (c.value ? { id: c.value } : { id: c }); }) : [] });
        delete campaign.dates;
        if (this.isEdit) {
            this.apiService.updateCampaign(campaign).subscribe(function (res) {
                _this.toastr.success(Success.CAMPAIGN_UPDATE);
                _this.form.markAsPristine();
            }, function (error) {
                _this.toastr.error(Errors.CAMPAIGN_UPDATE);
            });
        }
        else {
            delete campaign.id;
            this.apiService.createCampaign(campaign).subscribe(function (res) {
                _this.toastr.success(Success.CAMPAIGN_CREATE);
                _this.form.reset();
                _this.initForm();
            }, function (error) {
                _this.toastr.error(Errors.CAMPAIGN_CREATE);
            });
        }
    };
    EditComponent.prototype.initForm = function () {
        this.form.patchValue({
            id: null,
            name: "",
            accountId: "",
            channelType: "",
            channelTargeting: "",
            haul: null,
            outIn: null,
            status: this.status[0],
            type: null,
            owner: null,
            originId: null,
            originType: "",
            destinationId: null,
            destinationType: "",
            dates: [],
            budget: 0,
            cost: 0,
            landingPage: null,
            pos: null,
            targetLocation: null,
            creative: null,
            format: null,
            activityType: null,
            potentialReach: 0,
            tags: [],
        });
    };
    EditComponent.prototype.checkLocations = function (originDestination) {
        var _this = this;
        var formControl = this.form.get(originDestination + "Id");
        formControl.valueChanges.subscribe(function (id) {
            var error = { notValid: true };
            if (id) {
                var location_1 = _this.locations.find(function (l) { return l.id === id; });
                if (location_1) {
                    _this.form.get(originDestination + "Type").setValue(location_1.type);
                    error = null;
                }
            }
            else {
                error = null;
            }
            formControl.setErrors(error);
        });
    };
    return EditComponent;
}());
export { EditComponent };
