import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { TagInputModule } from "ngx-chips";
import { FileDropModule } from "ngx-file-drop";
import { ToastrModule } from "ngx-toastr";
import * as Raven from "raven-js";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ActionButtonComponent } from "./components/action-button/action-button.component";
import { CardComponent } from "./components/card/card.component";
import { ImportFromCsvComponent } from "./components/import-from-csv/import-from-csv.component";
import { ListComponent } from "./components/list/list.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { RemoveComponent } from "./components/remove/remove.component";
import { AccountsComponent } from "./pages/accounts/accounts.component";
import { LocationsComponent } from "./pages/locations/locations.component";
import { LoginComponent } from "./pages/login/login.component";
import { EditComponent } from "./pages/offline-campaigns/edit/edit.component";
import { OfflineCampaignsComponent } from "./pages/offline-campaigns/offline-campaigns.component";
import { NoopInterceptor } from "./services/interceptor/noop-interceptor";

Raven.config(environment.SENTRY_DSN).install();

export class RavenErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    console.error(error);
    Raven.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OfflineCampaignsComponent,
    AccountsComponent,
    EditComponent,
    RemoveComponent,
    ImportFromCsvComponent,
    ListComponent,
    PaginationComponent,
    ActionButtonComponent,
    LocationsComponent,
    CardComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    PaginationModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    FileDropModule,
    TagInputModule,
  ],
  entryComponents: [RemoveComponent, ImportFromCsvComponent],
  providers: [
    { provide: "Window", useValue: window },
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
