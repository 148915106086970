import { Component, OnInit, Output, EventEmitter, Input, AfterViewChecked } from "@angular/core";
import { IPageChangedEvent, ItemsPerPage } from "./pagination.interface";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() public itemsPerPage: ItemsPerPage = "10";
  @Input() public page = "1";
  @Input() public totalItems: string;

  @Output() public pageChangedEvent: EventEmitter<IPageChangedEvent> = new EventEmitter();

  constructor() {}

  public ngOnInit() {}

  public pageOrItemsPerPageChanged(event?): void {
    if (event) {
      this.page = event.page;
    }
    this.pageChangedEvent.emit({ page: this.page, itemsPerPage: this.itemsPerPage });
  }
}
