export enum constants {
  TOKEN = "token",
}

export enum entityPlural {
  LOCATIONS = "locations",
  CAMPAIGNS = "campaigns",
  ACCOUNTS_ONLINE = "accounts/online",
}

export enum entitySingular {
  LOCATION = "location",
  CAMPAIGN = "campaign",
  ACCOUNT = "account",
}
