<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
</div>
<div class="modal-body">{{text}}</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-default"
    (click)="confirm(true)"
  >
    ok
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="confirm(false)"
  >
    Cancel
  </button>
</div>
