<app-card title="Locations">
  <ul class="actions-group">
    <app-action-button (actionEvent)="openImportFromCsv()">Import from CSV</app-action-button>
    <app-action-button (actionEvent)="exportCSV()">Export CSV</app-action-button>
  </ul>
  <app-list
    [items]="list.items"
    [headerTemplate]="header"
    [bodyTemplate]="body"
    [emptyMessage]="emptyMessage"
    [loading]="list.loading"
  ></app-list>
  <ng-template #header>
    <th width="25%">Id</th>
    <th width="20%">Type</th>
    <th width="30%">Name</th>
    <th width="25%">ParentId</th>
  </ng-template>
  <ng-template
    #body
    let-location="item"
  >
    <td>{{ location.id }}</td>
    <td>{{ location.type }}</td>
    <td>{{ location.name }}</td>
    <td>{{ location.parentId }}</td>
  </ng-template>
  <app-pagination
    [totalItems]="list.totalItems"
    (pageChangedEvent)="pageChanged($event)"
  ></app-pagination>
</app-card>
