import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api/api.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import {
  ILocation,
  ICampaign,
  IListResponse,
  IAccount,
  ECampaignOwner,
  ECampaignType,
  ECampaignStatus,
  ECampaignOutIn,
  ECampaignHaul,
} from "src/app/services/api/api.interface";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { pathRoutes, Success, Errors } from "src/app/constants";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditComponent implements OnInit {
  public selected: string;
  public locations: ILocation[];
  public form: FormGroup;
  public isEdit: boolean;
  public campaign: Observable<ICampaign>;
  public locations$: Observable<IListResponse<ILocation>>;
  public offlineAccounts$: Observable<IListResponse<IAccount>>;
  public offlineAccounts: IAccount[];
  public readonly haul: ECampaignHaul[] = [ECampaignHaul.SH, ECampaignHaul.MH, ECampaignHaul.LH];
  public readonly outIn: ECampaignOutIn[] = [ECampaignOutIn.OUT, ECampaignOutIn.IN];
  public readonly status: ECampaignStatus[] = [ECampaignStatus.ACTIVE, ECampaignStatus.INACTIVE];
  public readonly campaignType: ECampaignType[] = [ECampaignType.B, ECampaignType.G];
  public readonly owner: ECampaignOwner[] = [ECampaignOwner.B2B, ECampaignOwner.B2C];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {}

  public ngOnInit() {
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    this.isEdit = id !== "new";
    this.locations = [];
    this.form = this.fb.group({
      id: [null],
      name: ["", Validators.required],
      accountId: ["", Validators.required],
      channelType: ["", Validators.required],
      channelTargeting: [""],
      haul: [""],
      outIn: [""],
      status: ["", Validators.required],
      type: [""],
      owner: [""],
      originId: [""],
      originType: [""],
      destinationId: [""],
      destinationType: [""],
      dates: ["", Validators.required],
      budget: ["", Validators.required],
      cost: ["", Validators.required],
      landingPage: [""],
      pos: [""],
      targetLocation: [""],
      creative: [""],
      format: [""],
      activityType: [""],
      potentialReach: ["", Validators.required],
      tags: [[]],
    });
    this.initForm();
    if (this.isEdit) {
      this.campaign = this.apiService.getOneCampaign(id).pipe(
        tap(campaign => {
          this.form.patchValue({
            ...campaign,
            tags: campaign.tags ? campaign.tags.map(t => t.id) : [],
            dates: [new Date(campaign.startDate), new Date(campaign.endDate)],
          });
        }),
      );
    }
    this.locations$ = this.apiService.getAllLocations().pipe(
      tap(({ list }) => {
        this.locations = list;
      }),
    );

    this.offlineAccounts$ = this.apiService.getOfflineAccounts().pipe(
      tap(({ list }) => {
        this.offlineAccounts = list;
      }),
    );

    this.checkLocations("destination");
    this.checkLocations("origin");
  }

  public gotToCampaigns(): void {
    this.router.navigate([pathRoutes.OFFLINE_CAMPAINGS]);
  }

  public save(): void {
    let campaign = this.form.value;
    campaign = {
      ...campaign,
      startDate: campaign.dates[0],
      endDate: campaign.dates[1],
      budget: campaign.budget.toString(),
      cost: campaign.cost.toString(),
      potentialReach: campaign.potentialReach.toString(),
      tags: campaign.tags ? campaign.tags.map(c => (c.value ? { id: c.value } : { id: c })) : [],
    };
    delete campaign.dates;
    if (this.isEdit) {
      this.apiService.updateCampaign(campaign).subscribe(
        res => {
          this.toastr.success(Success.CAMPAIGN_UPDATE);
          this.form.markAsPristine();
        },
        error => {
          this.toastr.error(Errors.CAMPAIGN_UPDATE);
        },
      );
    } else {
      delete campaign.id;
      this.apiService.createCampaign(campaign).subscribe(
        res => {
          this.toastr.success(Success.CAMPAIGN_CREATE);
          this.form.reset();
          this.initForm();
        },
        error => {
          this.toastr.error(Errors.CAMPAIGN_CREATE);
        },
      );
    }
  }

  private initForm() {
    this.form.patchValue({
      id: null,
      name: "",
      accountId: "",
      channelType: "",
      channelTargeting: "",
      haul: null,
      outIn: null,
      status: this.status[0],
      type: null,
      owner: null,
      originId: null,
      originType: "",
      destinationId: null,
      destinationType: "",
      dates: [],
      budget: 0,
      cost: 0,
      landingPage: null,
      pos: null,
      targetLocation: null,
      creative: null,
      format: null,
      activityType: null,
      potentialReach: 0,
      tags: [],
    });
  }

  private checkLocations(originDestination: "origin" | "destination"): void {
    const formControl = this.form.get(`${originDestination}Id`);
    formControl.valueChanges.subscribe((id: string) => {
      let error = { notValid: true };
      if (id) {
        const location = this.locations.find(l => l.id === id);
        if (location) {
          this.form.get(`${originDestination}Type`).setValue(location.type);
          error = null;
        }
      } else {
        error = null;
      }
      formControl.setErrors(error);
    });
  }
}
