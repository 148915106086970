/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/ngx-bootstrap/pagination/ngx-bootstrap-pagination.ngfactory";
import * as i5 from "ngx-bootstrap/pagination";
import * as i6 from "./pagination.component";
var styles_PaginationComponent = [i0.styles];
var RenderType_PaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " per page "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
export function View_PaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "select", [["class", "pagination--select form-control"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.itemsPerPage = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = (_co.pageOrItemsPerPageChanged() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaginationComponent_1)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(9, 3), (_l()(), i1.ɵeld(10, 0, null, null, 2, "pagination", [["class", "pagination--pagination"]], null, [[null, "pageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.pageOrItemsPerPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PaginationComponent_0, i4.RenderType_PaginationComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.PaginationComponent]), i1.ɵdid(12, 114688, null, 0, i5.PaginationComponent, [i1.ElementRef, i5.PaginationConfig, i1.ChangeDetectorRef], { maxSize: [0, "maxSize"], itemsPerPage: [1, "itemsPerPage"], totalItems: [2, "totalItems"] }, { pageChanged: "pageChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.itemsPerPage; _ck(_v, 4, 0, currVal_7); var currVal_8 = _ck(_v, 9, 0, 10, 20, 30); _ck(_v, 8, 0, currVal_8); var currVal_9 = 5; var currVal_10 = _co.itemsPerPage; var currVal_11 = _co.totalItems; _ck(_v, 12, 0, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i1.ɵdid(1, 114688, null, 0, i6.PaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i1.ɵccf("app-pagination", i6.PaginationComponent, View_PaginationComponent_Host_0, { itemsPerPage: "itemsPerPage", page: "page", totalItems: "totalItems" }, { pageChangedEvent: "pageChangedEvent" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
