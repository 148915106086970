export interface IListResponse<T> {
  list: T[];
  pages: number;
  totalItems: number;
}

export interface ILocation {
  id: string;
  name?: string;
  type?: string;
}

export interface ICampaign {
  id: string;
  name: string;
  accountId: string;
  isNew: boolean;
  account?: any;
  type?: any;
  outIn?: any;
  originId: string;
  destinationId: string;
  originType: string;
  destinationType: string;
  shortLongHau?: any;
  startDate: string;
  endDate: string;
  status?: any;
  budget: string;
  owner?: any;
  tags?: any;
  format?: any;
  potentialReach: string;
  cost?: any;
  landingPage?: any;
}

export enum EChannel {
  GOOGLE = "Google",
  FACEBOOK = "Facebook",
  OFFLINE = "Offline",
}

export enum ECampaignType {
  G = "G",
  B = "B",
}

export enum ECampaignOutIn {
  OUT = "Out",
  IN = "In",
}
export enum ECampaignHaul {
  SH = "SH",
  LH = "LH",
  MH = "MH",
}

export enum ECampaignOwner {
  B2B = "B2B",
  B2C = "B2C",
}

export enum ECampaignStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export interface IAccount {
  id: string;
  channel: EChannel;
  companyName?: string;
}
