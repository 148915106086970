import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { StringifyOptions } from "querystring";

@Component({
  selector: "app-action-button",
  templateUrl: "./action-button.component.html",
  styleUrls: ["./action-button.component.scss"],
})
export class ActionButtonComponent implements OnInit {
  @Input() public icon: string;
  @Output() private actionEvent: EventEmitter<void> = new EventEmitter();

  constructor() {}

  public ngOnInit() {}

  public action() {
    this.actionEvent.emit();
  }
}
