import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { constants } from "../constants";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor() {}

  public isAuthenticated(): boolean {
    if (this.getToken()) {
      return true;
    }
    return false;
  }

  public getToken(): string {
    return localStorage.getItem(constants.TOKEN);
  }

  public setToken(token: string): void {
    localStorage.setItem(constants.TOKEN, token);
  }

  public logOut(): void {
    localStorage.clear();
  }
}
