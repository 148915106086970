/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./action-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./action-button.component";
var styles_ActionButtonComponent = [i0.styles];
var RenderType_ActionButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionButtonComponent, data: {} });
export { RenderType_ActionButtonComponent as RenderType_ActionButtonComponent };
function View_ActionButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icon; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0); }); }
export function View_ActionButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "action-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionButtonComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ActionButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-action-button", [], null, null, null, View_ActionButtonComponent_0, RenderType_ActionButtonComponent)), i1.ɵdid(1, 114688, null, 0, i6.ActionButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActionButtonComponentNgFactory = i1.ɵccf("app-action-button", i6.ActionButtonComponent, View_ActionButtonComponent_Host_0, { icon: "icon" }, { actionEvent: "actionEvent" }, ["*"]);
export { ActionButtonComponentNgFactory as ActionButtonComponentNgFactory };
