export enum pathRoutes {
  OFFLINE_CAMPAINGS = "offline-campaigns",
  CAMPAING = "campaign",
  ACCOUNTS = "accounts",
  LOCATIONS = "locations",
  LOGIN = "login",
}

export enum Errors {
  CAMPAIGN_CREATE = "Can't create campaigns. Try again!",
  CAMPAIGN_READ = "Can't get campaigns",
  CAMPAIGN_UPDATE = "Can't update campaign. Try again!",
  CAMPAIGN_DELETE = "Can't delete campaign. Try again!",
  ACCOUNT_DELETE = "Can't delete account. Try again!",
  LOCATION_DELETE = "Can't delete location. Try again!",
  LOCATION_READ = "Can't get locations",

  UPLOAD_CSV = "Can't upload this file. Try again!",
  ACCOUNT_READ = "Can't get accounts",
}

export enum Success {
  CAMPAIGN_CREATE = "Campaign created",
  CAMPAIGN_UPDATE = "Campaign updated",
  CAMPAIGN_DELETE = "Campaign deleted",
  CAMPAIGNS_EMPTY = "No campaigns yet",
  ACCOUNT_DELETE = "Account deleted",
  ACCOUNT_EMPTY = "No sync accounts yet",
  LOCATION_EMPTY = "No sync locations yet",
  LOCATION_DELETE = "Location deleted",
  UPLOAD_CSV = "File uploaded",
  LOGIN = "Login success",
}
