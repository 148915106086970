<app-card title="Accounts">
  <ul class="actions-group">
    <li class="actions-group--action">
      <a
        class="button--facebook"
        [href]="authFacebook"
      >
        <fa-icon
          class="button--facebook__icon"
          [icon]="faFacebook"
        ></fa-icon>
        <span class="button--facebook__text">Add Facebook account</span>
      </a>
    </li>
    <li class="actions-group--action">
      <a
        class="button--google"
        [href]="authGoogle"
      >
        <fa-icon
          class="button--google__icon"
          [icon]="faGoogle"
        ></fa-icon>
        <span class="button--google__text">Add Google account</span>
      </a>
    </li>
  </ul>
  <app-list
    [items]="list.items"
    [headerTemplate]="header"
    [bodyTemplate]="body"
    [emptyMessage]="emptyMessage"
    [loading]="list.loading"
    (removeEvent)="openRemove($event)"
    [actions]="{remove: true}"
  ></app-list>
  <ng-template #header>
    <th width="25%">id</th>
    <th width="25%">Channel</th>
    <th width="40%">Name</th>
  </ng-template>
  <ng-template
    #body
    let-account="item"
  >
    <td>{{ account.id }}</td>
    <td>{{ account.channel }}</td>
    <td>{{ account.companyName }}</td>
  </ng-template>
  <app-pagination
    [totalItems]="list.totalItems"
    (pageChangedEvent)="pageChanged($event)"
  ></app-pagination>
</app-card>
