<div class="header">
  <div class="header__logo">
    <img
      class="header__logo--image"
      src="assets/icon/small-logo.svg"
    >
  </div>
  <div class="header__buttons">
    <button
      class="header__buttons--button"
      (click)="logout()"
    >
      Logout
    </button>
  </div>
</div>
<div class="content-app">
  <div class="content-app__menu">
    <ul class="content-app__menu__links">
      <li
        class="content-app__menu__links--link"
        *ngFor="let route of routes"
      >
        <a
          class="link"
          [routerLink]="route.path"
          routerLinkActive="--is-active"
        >
          <fa-icon
            class="link__icon"
            [icon]="route.icon"
          ></fa-icon>
          <span class="link__text">{{route.name}}</span>
        </a>
      </li>
    </ul>
    <div></div>
  </div>
  <div class="content-app__content">
    <router-outlet></router-outlet>
  </div>
</div>
