import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { IPageChangedEvent } from "src/app/components/pagination/pagination.interface";
import { IRemoveInputs } from "src/app/components/remove/remove.interface";
import { Errors, Success } from "src/app/constants";
import { IAccount } from "src/app/services/api/api.interface";
import { entityPlural, entitySingular } from "src/app/services/constants";
import { ListService } from "src/app/services/list/list.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.scss"],
  providers: [ListService],
})
export class AccountsComponent implements OnInit {
  public faFacebook = faFacebook;
  public faGoogle = faGoogle;
  public removeIcon = faTrashAlt;

  public authFacebook = `${environment.API}/auth/v1/facebook`;
  public authGoogle = `${environment.API}/auth/v1/google`;
  public readonly emptyMessage = Success.ACCOUNT_EMPTY;

  constructor(public list: ListService<IAccount>, private activatedRote: ActivatedRoute, private toastr: ToastrService) {}

  public ngOnInit(): void {
    const isLoginRedirect = this.activatedRote.snapshot.queryParamMap.get("login");
    if (isLoginRedirect) setTimeout(() => this.toastr.success(Success.LOGIN));
    this.pageChanged();
  }

  public async pageChanged(event?: IPageChangedEvent): Promise<void> {
    const response = await this.list.getItems(entityPlural.ACCOUNTS_ONLINE, event);
    if (!response) {
      this.toastr.error(Errors.ACCOUNT_READ);
    }
  }

  public async openRemove(account: IAccount): Promise<void> {
    const modal: IRemoveInputs = { text: "If you delete this account, all related campaigns will be deleted. Are you sure?" };
    const res = await this.list.openRemove(entitySingular.ACCOUNT, account.id, modal);
    if (res === "success") {
      this.toastr.success(Success.ACCOUNT_DELETE);
      this.pageChanged();
    } else if (res === "error") {
      this.toastr.error(Errors.ACCOUNT_DELETE);
    }
  }
}
