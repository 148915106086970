import { Component, Inject } from "@angular/core";
import { faUserCircle, faAd, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "./services/auth/auth.service";
import { pathRoutes } from "./constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public readonly routes = [
    { name: "Accounts", path: pathRoutes.ACCOUNTS, icon: faUserCircle },
    { name: "Offline campaigns", path: pathRoutes.OFFLINE_CAMPAINGS, icon: faAd },
    { name: "Locations", path: pathRoutes.LOCATIONS, icon: faSearchLocation },
  ];

  constructor(private authService: AuthService, @Inject("Window") private window: Window) {}

  public logout(): void {
    this.authService.logOut();
    this.window.location.reload();
  }
}
