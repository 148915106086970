import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OfflineCampaignsComponent } from "./pages/offline-campaigns/offline-campaigns.component";
import { LoginComponent } from "./pages/login/login.component";
import { AuthGuard } from "./guards/auth.guard";
import { AccountsComponent } from "./pages/accounts/accounts.component";
import { EditComponent } from "./pages/offline-campaigns/edit/edit.component";
import { pathRoutes } from "./constants";
import { LocationsComponent } from "./pages/locations/locations.component";

const routes: Routes = [
  {
    path: pathRoutes.OFFLINE_CAMPAINGS,
    component: OfflineCampaignsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${pathRoutes.OFFLINE_CAMPAINGS}/:page`,
    component: OfflineCampaignsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${pathRoutes.CAMPAING}/:id`,
    component: EditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: pathRoutes.ACCOUNTS,
    component: AccountsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: pathRoutes.LOCATIONS,
    component: LocationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: pathRoutes.LOGIN,
    component: LoginComponent,
  },
  {
    path: "**",
    redirectTo: pathRoutes.OFFLINE_CAMPAINGS,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
