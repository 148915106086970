import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-remove",
  templateUrl: "./remove.component.html",
  styleUrls: ["./remove.component.scss"],
})
export class RemoveComponent implements OnInit {
  @Output() public confirmEvent = new EventEmitter<boolean>();

  @Input() public title: string;
  @Input() public text: string;
  constructor() {}

  public ngOnInit() {}

  public confirm(confirm: boolean): void {
    this.confirmEvent.emit(confirm);
  }
}
