<section class="big-card">
  <div class="big-card__header">
    <div class="big-card__header__title">
      <h2>{{title}}</h2>
    </div>
    <div class="big-card__content">
      <ng-content></ng-content>
    </div>
  </div>
</section>
