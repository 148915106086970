/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offline-campaigns.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/card/card.component.ngfactory";
import * as i4 from "../../components/card/card.component";
import * as i5 from "../../components/action-button/action-button.component.ngfactory";
import * as i6 from "../../components/action-button/action-button.component";
import * as i7 from "../../components/list/list.component.ngfactory";
import * as i8 from "../../components/list/list.component";
import * as i9 from "../../components/pagination/pagination.component.ngfactory";
import * as i10 from "../../components/pagination/pagination.component";
import * as i11 from "../../services/list/list.service";
import * as i12 from "../../services/api/api.service";
import * as i13 from "ngx-bootstrap/modal";
import * as i14 from "./offline-campaigns.component";
import * as i15 from "@angular/router";
import * as i16 from "ngx-toastr";
var styles_OfflineCampaignsComponent = [i0.styles];
var RenderType_OfflineCampaignsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfflineCampaignsComponent, data: {} });
export { RenderType_OfflineCampaignsComponent as RenderType_OfflineCampaignsComponent };
function View_OfflineCampaignsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Media"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Origin"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Destination"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Budget"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["P. Reach"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dates"]))], null, null); }
function View_OfflineCampaignsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " - ", " "])), i1.ɵppd(15, 2), i1.ɵppd(16, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.item.accountId; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.item.name; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.item.originId; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.item.destinationId; _ck(_v, 7, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.item.budget, "EUR")); _ck(_v, 9, 0, currVal_4); var currVal_5 = _v.context.item.potentialReach; _ck(_v, 12, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent, 1), _v.context.item.startDate, "dd/MM/yyyy")); var currVal_7 = i1.ɵunv(_v, 14, 1, _ck(_v, 16, 0, i1.ɵnov(_v.parent, 1), _v.context.item.endDate, "dd/MM/yyyy")); _ck(_v, 14, 0, currVal_6, currVal_7); }); }
export function View_OfflineCampaignsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 18, "app-card", [["title", "Offline campaings"]], null, null, null, i3.View_CardComponent_0, i3.RenderType_CardComponent)), i1.ɵdid(3, 114688, null, 0, i4.CardComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 9, "ul", [["class", "actions-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "app-action-button", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.openEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ActionButtonComponent_0, i5.RenderType_ActionButtonComponent)), i1.ɵdid(6, 114688, null, 0, i6.ActionButtonComponent, [], { icon: [0, "icon"] }, { actionEvent: "actionEvent" }), (_l()(), i1.ɵted(-1, 0, [" Add new "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "app-action-button", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.openImportFromCsv() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ActionButtonComponent_0, i5.RenderType_ActionButtonComponent)), i1.ɵdid(9, 114688, null, 0, i6.ActionButtonComponent, [], null, { actionEvent: "actionEvent" }), (_l()(), i1.ɵted(-1, 0, ["Import from CSV"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-action-button", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.exportCSV() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ActionButtonComponent_0, i5.RenderType_ActionButtonComponent)), i1.ɵdid(12, 114688, null, 0, i6.ActionButtonComponent, [], null, { actionEvent: "actionEvent" }), (_l()(), i1.ɵted(-1, 0, ["Export CSV"])), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "app-list", [], null, [[null, "editEvent"], [null, "removeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editEvent" === en)) {
        var pd_0 = (_co.openEdit($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeEvent" === en)) {
        var pd_1 = (_co.openRemove($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_ListComponent_0, i7.RenderType_ListComponent)), i1.ɵdid(15, 114688, null, 0, i8.ListComponent, [], { loading: [0, "loading"], items: [1, "items"], emptyMessage: [2, "emptyMessage"], headerTemplate: [3, "headerTemplate"], bodyTemplate: [4, "bodyTemplate"], actions: [5, "actions"] }, { editEvent: "editEvent", removeEvent: "removeEvent" }), i1.ɵpod(16, { remove: 0, edit: 1 }), (_l()(), i1.ɵand(0, [["header", 2]], 0, 0, null, View_OfflineCampaignsComponent_1)), (_l()(), i1.ɵand(0, [["body", 2]], 0, 0, null, View_OfflineCampaignsComponent_2)), (_l()(), i1.ɵeld(19, 0, null, 0, 1, "app-pagination", [], null, [[null, "pageChangedEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChangedEvent" === en)) {
        var pd_0 = (_co.pageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_PaginationComponent_0, i9.RenderType_PaginationComponent)), i1.ɵdid(20, 114688, null, 0, i10.PaginationComponent, [], { totalItems: [0, "totalItems"] }, { pageChangedEvent: "pageChangedEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Offline campaings"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.addIcon; _ck(_v, 6, 0, currVal_1); _ck(_v, 9, 0); _ck(_v, 12, 0); var currVal_2 = _co.list.loading; var currVal_3 = _co.list.items; var currVal_4 = _co.emptyMessage; var currVal_5 = i1.ɵnov(_v, 17); var currVal_6 = i1.ɵnov(_v, 18); var currVal_7 = _ck(_v, 16, 0, true, true); _ck(_v, 15, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.list.totalItems; _ck(_v, 20, 0, currVal_8); }, null); }
export function View_OfflineCampaignsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-offline-campaigns", [], null, null, null, View_OfflineCampaignsComponent_0, RenderType_OfflineCampaignsComponent)), i1.ɵprd(512, null, i11.ListService, i11.ListService, [i12.ApiService, i13.BsModalService]), i1.ɵdid(2, 114688, null, 0, i14.OfflineCampaignsComponent, [i11.ListService, i15.Router, i16.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var OfflineCampaignsComponentNgFactory = i1.ɵccf("app-offline-campaigns", i14.OfflineCampaignsComponent, View_OfflineCampaignsComponent_Host_0, {}, {}, []);
export { OfflineCampaignsComponentNgFactory as OfflineCampaignsComponentNgFactory };
