import { ErrorHandler } from "@angular/core";
import * as Raven from "raven-js";
import { environment } from "src/environments/environment";
Raven.config(environment.SENTRY_DSN).install();
var RavenErrorHandler = /** @class */ (function () {
    function RavenErrorHandler() {
    }
    RavenErrorHandler.prototype.handleError = function (error) {
        console.error(error);
        Raven.captureException(error.originalError || error);
    };
    return RavenErrorHandler;
}());
export { RavenErrorHandler };
var ɵ0 = window;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
