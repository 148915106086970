import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService) {
        this.authService = authService;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        if (!this.authService.isAuthenticated()) {
            this.redirect();
            return false;
        }
        return true;
    };
    AuthGuard.prototype.redirect = function () {
        location.href = environment.API + "/auth/v1/azure";
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
