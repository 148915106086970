import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(httpClient) {
        this.httpClient = httpClient;
        this.apiUrl = environment.API + "/api/v1";
    }
    ApiService.prototype.getPaginated = function (entity, page, limit) {
        return this.httpClient.get(this.apiUrl + "/" + entity, { params: { page: page, limit: limit } });
    };
    ApiService.prototype.delete = function (entity, id) {
        return this.httpClient.delete(this.apiUrl + "/" + entity + "/" + id);
    };
    ApiService.prototype.getCampaigns = function (page, limit) {
        return this.httpClient.get(this.apiUrl + "/campaign", { params: { page: page, limit: limit } });
    };
    ApiService.prototype.getOneCampaign = function (id) {
        return this.httpClient.get(this.apiUrl + "/campaign/" + id);
    };
    ApiService.prototype.createCampaign = function (campaign) {
        return this.httpClient.post(this.apiUrl + "/campaign", campaign);
    };
    ApiService.prototype.updateCampaign = function (campaign) {
        return this.httpClient.put(this.apiUrl + "/campaign/" + campaign.id, campaign);
    };
    ApiService.prototype.getAllLocations = function () {
        return this.httpClient.get(this.apiUrl + "/locations");
    };
    ApiService.prototype.importCSV = function (entity, file) {
        var formData = new FormData();
        formData.append("file", file);
        return this.httpClient.post(this.apiUrl + "/" + entity + "/file/import", formData);
    };
    ApiService.prototype.exportCSV = function (entity) {
        var headers = new HttpHeaders().set("Content-Type", "text/csv");
        return this.httpClient.get(this.apiUrl + "/" + entity + "/file/export", {
            responseType: "arraybuffer",
            headers: headers,
        });
    };
    ApiService.prototype.getOfflineAccounts = function () {
        return this.httpClient.get(this.apiUrl + "/accounts/offline");
    };
    ApiService.prototype.getOnlineAccounts = function (page, limit) {
        return this.httpClient.get(this.apiUrl + "/accounts/online", { params: { page: page, limit: limit } });
    };
    ApiService.prototype.deleteAccount = function (id) {
        return this.httpClient.delete(this.apiUrl + "/account/" + id);
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
